$sm: 550px
$md: 990px

$black: #1E1E1E
$primary: white
$secondary: #1B2333
$accent: #11B3AC
$grey: #BDBDBD

$heading-font: 'Kanit'
$body-font: 'Sarabun'

