@use 'variables' as *
@use 'fonts'

@use 'navbar'
@use 'hero'
@use 'main'
@use 'footer'

@import '~bootstrap/scss/bootstrap'
@import '~bootstrap-vue/src/index.scss'

body
  margin: 0
  font-weight: 500
  font-family: $heading-font, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  min-height: 100vh
  color: $black

*
  box-sizing: border-box

h1, h2, h3, h4, h5, h6
  font-family: $heading-font

.btn-secondary
  background: $secondary
  color: white
  border: 1px solid transparent
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
  font-size: 18px
  border-radius: 5em !important
  transition: all 0.5s cubic-bezier(0, 1.06, 0.58, 1)

  &:hover
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3)

.navbar-light .navbar-toggler-icon
  background-image: none
